<template>
  <div class="" style="background: #fff">
    <div class="circles-header-wrap">
      <div class="circles-header-cont space-between">
        <div class="header-cont-left vertical-center">
          <img src="~assets/images/public/logo.png" class="header-logo" alt="" />
          <p class="hint-p">医生为本，取之于医，用之于医</p>
        </div>
        <div class="header-cont-right vertical-center" style="font-size: 16px;font-weight: 400;color: #FFFFFF;" v-if="!userInfo.id">
          <span @click="$router.push('/login')" style="cursor: pointer" class="login">登录</span>
          <span style="margin: 0 20px;color: rgba(255, 255, 255, 0.7)">/</span>
          <span @click="$router.push('/register')" style="margin-right: 20px;cursor: pointer" class="login">注册</span>
          <!--          <div class="header-user-box vertical-center">-->
          <!--            <div class="user-info-box">-->
          <!--              <img src="" class="user-avatar" alt="">-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <!--        <div class="header-cont-right vertical-center" v-else>-->
        <!--          <p class="login-time">登录时间：{{userInfo.last_login_at}}</p>-->
        <!--          <div class="gold vertical-center">-->
        <!--            <img src="~assets/images/index/gold.png" class="goldimg" alt="">-->
        <!--            <p>当前金币：{{ userInfo.account && userInfo.account.gold }}</p>-->
        <!--          </div>-->
        <!--          <div class="recharge align-center">-->
        <!--            去充值-->
        <!--            <div class="service">-->
        <!--              <img src="~assets/images/data/play.png" class="serviceimg" alt="">-->
        <!--              <p>请联系客服人员</p>-->
        <!--            </div>-->
        <!--            <div class="recharge-b"></div>-->
        <!--          </div>-->

        <!--          <div class="header-user-box">-->
        <!--            <div class="user-info-box vertical-center">-->
        <!--              <img :src="userInfo.avatar" class="user-avatar" alt="">-->
        <!--              &lt;!&ndash;          <p class="user-name white-space">{{userInfo.nickname}}</p>&ndash;&gt;-->
        <!--            </div>-->
        <!--            <div class="header-user-cont">-->
        <!--              <div class="user-top vertical-center">-->
        <!--                <img :src="userInfo.avatar" class="user-avatar" alt="">-->
        <!--                <div class="">-->
        <!--                  <p class="user-name white-space" :title="userInfo.nickname">{{userInfo.nickname}}</p>-->
        <!--                  <div class="auth auth-h align-center" v-if="userInfo.is_approval">-->
        <!--                    <img src="~assets/images/header/auth-h.png" alt="" class="authimg">-->
        <!--                    <span>已认证</span>-->
        <!--                  </div>-->
        <!--                  &lt;!&ndash;              <div class="auth align-center" v-else>&ndash;&gt;-->
        <!--                  &lt;!&ndash;                <img src="~assets/images/header/auth.png" alt="" class="authimg">&ndash;&gt;-->
        <!--                  &lt;!&ndash;                <span >未认证</span>&ndash;&gt;-->
        <!--                  &lt;!&ndash;              </div>&ndash;&gt;-->
        <!--                </div>-->

        <!--              </div>-->
        <!--              <div class="set-btn-box">-->
        <!--                <div class="set-btn-item user-set-btn vertical-center" @click="userLink">-->
        <!--                  <img src="~assets/images/header/user-icon.png" alt="" class="user-img">-->
        <!--                  <img src="~assets/images/header/user-icon-h.png" alt="" class="user-img-h">-->
        <!--                  <p>我的资料</p>-->
        <!--                </div>-->
        <!--                <div class="set-btn-item password vertical-center" @click="$router.push('/password')">-->
        <!--                  <img src="~assets/images/header/password.png" alt="" class="password-img">-->
        <!--                  <img src="~assets/images/header/password-h.png" alt="" class="password-img-h">-->
        <!--                  <p>修改密码</p>-->
        <!--                </div>-->
        <!--                <div class="set-btn-item quit vertical-center" @click="quit">-->
        <!--                  <img src="~assets/images/header/quit-icon.png" alt="" class="quit-img">-->
        <!--                  <img src="~assets/images/header/quit-icon-h.png" alt="" class="quit-img-h">-->
        <!--                  <p>退出登录</p>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="occupied-box"></div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="banner">
      <img :src="img" class="bannerimg" alt="" />

      <div class="banner-c">
        <div class="banner-center">
          <div class="banner-top">
            君莲书院
          </div>
          <div class="banner-platform">
            中国临床医生学术成长平台
          </div>
          <div class="banner-content">
            解螺旋旗下的医生、医学生知识社区，依据研究兴趣分类的体系化课程，最前沿的行业资讯，丰富的短视频与直播内容，助力医生科研能力成长。
          </div>
          <div class="banner-app flex-nowrap">
            <div class="banner-app-l">
              <div class="banner-app-l-t align-center">
                <img src="~assets/images/auth/ios-icon-h.png" class="app" alt="" />
                App Store下载
              </div>
              <div class="banner-app-l-b align-center">
                <img src="~assets/images/auth/android-icon-h.png" class="app" alt="" />
                Android下载
              </div>
            </div>
            <div class="banner-app-r">
              <img src="https://static.helixlife.cn/circle/images/download-app.png" class="appcode" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-center">
        <div class="content-title">
          <div class="content-title-t">
            关于我们
          </div>
          <div class="content-title-b">
            为医生/医学生提供高质量的学习和行业前沿进展，加速科研能力成长
          </div>
        </div>
        <div class="nav-list space-between">
          <div class="nav-item">
            <img src="~assets/images/header/nav-1.png" class="nav-item-img" alt="" />
            <div class="nav-item-title">
              知识社区
            </div>
            <div class="nav-item-content">
              根据兴趣分类的体系化课程，知识全面无死角
            </div>
          </div>
          <div class="nav-item">
            <img src="~assets/images/header/nav-2.png" class="nav-item-img" alt="" />
            <div class="nav-item-title">
              行业资讯
            </div>
            <div class="nav-item-content">
              医学行业最新研究进展，紧跟行业前沿
            </div>
          </div>
          <div class="nav-item">
            <img src="~assets/images/header/nav-3.png" class="nav-item-img" alt="" />
            <div class="nav-item-title">
              科研短视频
            </div>
            <div class="nav-item-content">
              最新知识传播形式，提供丰富有趣的知识内容
            </div>
          </div>
          <div class="nav-item">
            <img src="~assets/images/header/nav-4.png" class="nav-item-img" alt="" />
            <div class="nav-item-title">
              医学直播
            </div>
            <div class="nav-item-content">
              大咖互动直播，提升学习体验和学习效率
            </div>
          </div>
        </div>
        <div class="content-center-bottom space-between">
          <div class="content-center-bottom-l ">
            <div class="content-center-bottom-l-t">
              为什么选择我们
            </div>
            <div class="content-center-bottom-l-b">
              云集众多医学专业培训讲师，精通基础，生信和临床科研等医学科研技能；具备10万医生科研社群，抱团取暖，共同成长。
            </div>
          </div>
          <div class="content-center-bottom-r vertical-center">
            <div class="nav-item">
              <img src="~assets/images/header/nav-5.png" class="nav-item-img" alt="" />
              <img src="~assets/images/header/nav-5-h.png" class="nav-item-img-h" alt="" />
              <div class="nav-item-title">
                知识内容全面无死角
              </div>
              <div class="nav-item-content">
                体系课程涵盖基础，生信和临床3个方向，满足医生科研所有需求
              </div>
            </div>
            <div class="nav-item">
              <img src="~assets/images/header/nav-6.png" class="nav-item-img" alt="" />
              <img src="~assets/images/header/nav-6-h.png" class="nav-item-img-h" alt="" />
              <div class="nav-item-title">
                知识内容形式多样
              </div>
              <div class="nav-item-content">
                提供多种学习体验，包含录播视频课，图文干货，短视频和直播内容
              </div>
            </div>
            <div class="nav-item">
              <img src="~assets/images/header/nav-7.png" class="nav-item-img" alt="" />
              <img src="~assets/images/header/nav-7-h.png" class="nav-item-img-h" alt="" />
              <div class="nav-item-title">
                紧跟行业研究前沿
              </div>
              <div class="nav-item-content">
                行业大咖不定时分享科学前沿进展，结合自身研究提升科研水平
              </div>
            </div>
            <div class="nav-item">
              <img src="~assets/images/header/nav-8.png" class="nav-item-img" alt="" />
              <img src="~assets/images/header/nav-8-h.png" class="nav-item-img-h" alt="" />
              <div class="nav-item-title">
                与十万医生医学生同行
              </div>
              <div class="nav-item-content">
                一个人走得更快，一群人走得更远，加入医学社区与10万伙伴一起成长
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-wrap align-center">
      <p class="footer-text">
        {{ $static.footer_text }}<a href="https://beian.miit.gov.cn" target="_blank" class="records-link">{{ $static.record_no }}</a>
      </p>
    </div>
  </div>
</template>

<script>
// import user from "@/utils/user";
export default {
  name: 'Header',
  data() {
    return {
      imglist: [
        require('../../../assets/images/index/banner.png'),
        require('../../../assets/images/index/banner-1.png'),
        require('../../../assets/images/index/banner-2.png'),
        require('../../../assets/images/index/banner-3.png'),
        require('../../../assets/images/index/banner-4.png'),
      ],
      img: require('../../../assets/images/index/banner.png'),
      i: 0,
    };
  },
  methods: {
    // 用户信息
    userLink() {
      this.$router.push('/profile');
    },
    // 退出登录
    quit() {
      this.$cache.removeLoginInfo();
      this.$router.push('/login');
    },
  },
  created() {
    // user.getUserInfo()
    setInterval(() => {
      if (this.i == 5) {
        this.i = 0;
      }
      this.img = this.imglist[this.i];
      this.i++;
    }, 3000);
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/header/header.less';
@import '~assets/less/home/index.less';
.footer-text {
  a {
    margin-left: 6px;
    color: #93999f;
    text-decoration: none;
  }
}
</style>
